.col-profile-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    p {
        color: white;
        margin-bottom: 8px !important;
    }

}