.container-page-login {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100vw;
    height: 100vh;

    .ant-col-md-20, .ant-col-sm-20 {
        padding: 0px !important;
    }

    .container-form-login {

        .ant-form-item {
            margin: 0px 0px 10px !important;
        }

        .ant-col-24 {
            padding: 0px !important;
            margin: 0px !important;
        }

        .ant-col-22 {
            padding: 0px !important;
            margin: 0px !important;
        }

        .ant-col-2 {
            padding: 0px !important;
            margin: 0px !important;
        }

        .input-double {
            display: flex;
            justify-content: space-between;
            margin-bottom: -20px;
        }

        .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            display: none !important;
        }

        .btn-submit {
    
            .ant-btn-primary {
                background-color: #e88634 !important;
                border-color: #e88634 !important;
                color: white !important;
                width: 100% !important;
                margin: 0 !important;
            }
        
            .ant-btn-primary:hover, .ant-btn-primary:focus {
                background-color: #e88634 !important;
                border-color: #e88634 !important;
                color: white !important;
            }
    
        }

    }

}