.gx-sidebar-notifications {
    padding: 10px 5px 10px !important;
    margin: 0 0px 0px !important;
    border-bottom: solid 1px grey !important;
}

.gx-sidebar-content {
    border-right: solid 1px grey !important;
    height: 100% !important;
}

.app-navigation {

    .ant-col-6 {
        padding: 0px 5px !important;
    }

    .btn-1 {

        .ant-btn-primary {
            background-color: #87b87f !important;
            border-color: #87b87f !important;
            width: 100% !important;
            border-radius: 0px !important;
            margin: 0px !important;
        }
    
        .ant-btn-primary:hover, .ant-btn-primary:focus {
            background-color: #87b87f !important;
            border-color: #87b87f !important;
        }

    }

    .btn-2 {

        .ant-btn-primary {
            background-color: #6fb3e0 !important;
            border-color: #6fb3e0 !important;
            width: 100% !important;
            border-radius: 0px !important;
            margin: 0px !important;
        }
    
        .ant-btn-primary:hover, .ant-btn-primary:focus {
            background-color: #6fb3e0 !important;
            border-color: #6fb3e0 !important;
        }

    }

    .btn-3 {

        .ant-btn-primary {
            background-color: #ffb752 !important;
            border-color: #ffb752 !important;
            width: 100% !important;
            border-radius: 0px !important;
            margin: 0px !important;
        }
    
        .ant-btn-primary:hover, .ant-btn-primary:focus {
            background-color: #ffb752 !important;
            border-color: #ffb752 !important;
        }

    }

    .btn-4 {

        .ant-btn-primary {
            background-color: #d15b47 !important;
            border-color: #d15b47 !important;
            width: 100% !important;
            border-radius: 0px !important;
            margin: 0px !important;
        }
    
        .ant-btn-primary:hover, .ant-btn-primary:focus {
            background-color: #d15b47 !important;
            border-color: #d15b47 !important;
        }

    }


}

@media screen and (max-width: 100px) { 
    .app-navigation {
        display: 'none';
    }
}